.search {
    display: flex;
    max-width: 800px;
    margin: 0 auto;
}

.search .search-input {
    width: 100%;
    margin: 40px 10px 40px 40px;
    height: 48px;
    border-radius: 24px;
    border: 0;
    background-color: #fff;
    text-indent: 40px;
    font-size: 18px;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
}

.search-button {
    margin: 40px 10px 40px 0;
    padding: 0 20px;
    border-radius: 40px;
    background: #efefef;
    border: 0;
    font-size: 18px;
    font-weight: medium;
    color: #666;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

.search-button:hover {
    box-shadow: 0 4px 2px rgba(0, 0, 0, 0.3);
}

.search-button:active {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
}

.quicklinks {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 180px;
    max-width: 100%;
    margin: 0 auto;
}

@media screen and (min-width: 400px) {
    .quicklinks {
        grid-template-columns: repeat(4, 1fr);
        gap: 40px;
        width: 400px;
    }
}

.quicklinks .item {
    height: 60px;
    width: 60px;
    justify-self: center;
    align-self: center;
    text-align: center;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.quicklinks .item:hover {
    transform: scale(1.1);
}

.quicklinks .item:hover .icon {
    background-color: #ffecde;
}
.quicklinks .icon {
    box-sizing: border-box;
    transition: all 0.1s ease-in-out;
    background-color: #f4f4f4;
    border-radius: 60px;
    width: 60px;
    height: 60px;
    padding: 10px;
}

.quicklinks .icon img {
    display: flex;
    max-width: 100%;
    border-radius: 60px;
    overflow: hidden;
}
