body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.header {
    padding-top: 40px;
    width: 100%;
}

.header img {
    margin: 0 auto;
    display: block;
    width: auto;
    max-width: 80vw;
}

#logo {
    text-align: left;
    width: 90vw;
    position: relative;
    left: 5vw;
    font-size: 1.75rem;
    font-family: 'Playfair Display', serif;
    font-weight: 500;
    margin-block-start: 0px;
    margin-block-end: 0.25rem;
    color: #6c63ff;
}

#poweredby {
    text-align: left;
    width: 90vw;
    position: relative;
    left: 5vw;
    font-size: 0.9rem;
    font-weight: 500;
    margin-block-start: 0px;
    margin-block-end: 0.25rem;
    color: #6c63ff;
}

#poweredby a {
    color: #1dcf96;
}



.content {
  max-width: 600px;
  margin: 0px auto 2rem;
  padding-bottom: 2rem;
}

#contact {
  border: none;
  max-width: 100%;
  max-height: 100vh;
  min-height: 1000px;
}

footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  text-align: center;
  font-size: 0.85rem;
  background-color: #ffeddd;
  padding: 0.33rem;
}

a {
  padding: 0px 4px;
  color: #111;
}

@media screen and (max-width: 600px) {
  .header {
      padding-top: 20px;
      width: 100%;
  }

  #logo {
      font-size: 1.25rem;
  }

  .content {
    margin: 0px 1rem 2rem;
  }
}